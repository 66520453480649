import hoomans from './hoomans.svg';
import './App.css';

function App() {
  return (
    <div 
      style={{
        backgroundImage: `url(${hoomans})`,
        height: '100vh',
        width: '100vw',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }} 
    alt="hoomans"></div>
  );
}

export default App;
